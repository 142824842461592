<template>
  <div>
    <pop-ship></pop-ship>
  </div>
</template>

<script>
import { default as mapApp } from '@/map/init.js'
import popShip from '../map/popMarker/pop-ship.vue'
export default {
  // 组件名称
  name: 'Seabed',
  components: { popShip },
  data() {
    return {
      showSeabed: true
    }
  },
  watch: {
    'mapApp.inSeabedShow': {
      handler: (val) => {}
    }
  },
  created() {},
  mounted() {
    if (mapApp.isInitComplete) {
      this.enterUnderwater()
    } else {
      mapApp.addEventListener('mapAppInitComplete', () => {
        this.enterUnderwater()
      })
    }
  },
  beforeDestroy() {
    this.quitUnderwater()
  },
  methods: {
    //进入海底视角
    enterUnderwater() {
      const this_ = this
      //开启海底光照效果
      esmap.ESEffectTool.models.showTerrainWaterEffect({ name: 'sea_ground', isShow: true })
      esmap.ESEffectTool.models.showTerrainWaterEffect({ name: 'sea_ground_wall1', isShow: true })
      esmap.ESEffectTool.models.showTerrainWaterEffect({ name: 'sea_ground_wall2', isShow: true })
      esmap.ESEffectTool.models.showTerrainWaterEffect({ name: 'sea_ground_wall3', isShow: true })
      esmap.ESEffectTool.models.showTerrainWaterEffect({ name: 'sea_ground_wall4', isShow: true })
      esmap.ESEffectTool.models.showTerrainWaterEffect({ name: 'sea_ground_nitu', isShow: true })
      mapApp.map.cameraFlyTo(
        // 视角移动方法，执行时有飞行动画过程
        {
          directionAngle: 238.44,
          pitchAngle: 81,
          radius: 70.55,
          x: 13151073.77,
          y: 4750226.25,
          z: -30,
          time: 3,
          callback() {
            this_.$nextTick(() => {
              mapApp.boatsMapActions.shipPopVueComponent.popClose()
              mapApp.homeVueComponent.btnFold(false)
              mapApp.map.controls.minPolarAngle = THREE.Math.degToRad(70.42)
              mapApp.map.controls.maxDistance = 85
              mapApp.inSeabedShow = true
              //设置水面切割视野
              mapApp.map.mapView.camera.near = 10
              mapApp.map.mapView.camera.updateProjectionMatrix()
            })
          }
        }
      )
    },
    //退出海底视角
    quitUnderwater() {
      mapApp.map.controls.minPolarAngle = 0
      mapApp.map.controls.maxDistance = Infinity
      mapApp.inSeabedShow = false
      mapApp.homeVueComponent.btnFold(true)
      esmap.ESEffectTool.models.showTerrainWaterEffect({ name: 'sea_ground', isShow: false })
      esmap.ESEffectTool.models.showTerrainWaterEffect({ name: 'sea_ground_wall1', isShow: false })
      esmap.ESEffectTool.models.showTerrainWaterEffect({ name: 'sea_ground_wall2', isShow: false })
      esmap.ESEffectTool.models.showTerrainWaterEffect({ name: 'sea_ground_wall3', isShow: false })
      esmap.ESEffectTool.models.showTerrainWaterEffect({ name: 'sea_ground_wall4', isShow: false })
      esmap.ESEffectTool.models.showTerrainWaterEffect({ name: 'sea_ground_nitu', isShow: false })
      mapApp.map.mapView.camera.near = 0.1
      mapApp.map.mapView.camera.updateProjectionMatrix()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
